import { httpClient } from '@/utils/http-client'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { maybeInitFacebook, logRocketIdentify } from '@/services/marketing'
import { Flows } from '@/flow/flowController'
import { logger } from '@/utils/logger'

const verifyEmployerEmail = async (verificationCode: string) => {
    const postBody = {
        auth: verificationCode,
    }
    const response = await httpClient.post('/verifyEmployerEmail', postBody)
    return response
}

// TODO: delete this?
const isEmployerEmailVerified = async () => {
    const response = await httpClient.get('/verifyEmployerEmail')
    return response
}

const getLead = async () => {
    const phoneNumber = appSessionStorage.getItem(sessionStorageKey.phoneNumber)
    const inviteCode = appSessionStorage.getItem(sessionStorageKey.inviteCode) || appSessionStorage.getItem(sessionStorageKey.pifInviteCode)
    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)

    const payload = {
        phoneNumber,
        sessionId,
        inviteCode,
    }
    const response = await httpClient.post('/lead', payload)
    saveAuthResponse(response)
    logRocketIdentify()
    if (response.data.payload) {
        // When the response is an error there is no payload
        maybeInitFacebook(response.data.payload.facebookExternalId)
    }
    return response
}

/* if loan app id included in app session storage, we are using getLead to make new loan app based off the given loan app
 *currently this strategy is used to underwrite applicant who was previously in terminal state (denied) by allowing them to add joint co applicant.
 */
const createLoanAppFromExistingLoanApp = async (loanApplicationId: number) => {
    const payload = {
        loanApplicationId,
    }
    const response = await httpClient.post('/createLoanAppFromExistingLoanApp', payload)
    saveAuthResponse(response)
    logRocketIdentify()
    maybeInitFacebook(response.data.payload.facebookExternalId)
    return response
}

const setApplicationAndApplicant = async (loanApplicationId: string, applicantId: string) => {
    await httpClient.post('/setApplicationAndApplicant', {
        selectedLoanAppId: `${loanApplicationId}`,
        selectedApplicantId: `${applicantId}`,
    })
}

const getRealEstateAgentLead = async (phoneNumber: number) => {
    return await httpClient.post('/real_estate_agent/lead', { phoneNumber })
}

const createCoApplicantLead = async () => {
    const response = await httpClient.get('/createCoApplicant')
    saveCoApplicantAuthResponse(response)
    return response
}

const saveAuthResponse = (response: { data: any }) => {
    if (response.data.success) {
        appSessionStorage.setItem(sessionStorageKey.jwtTokens, JSON.stringify(response.data.payload.jwtTokens))
        appSessionStorage.setItem(sessionStorageKey.firstName, response.data.payload.firstName || '')
        appSessionStorage.setItem(sessionStorageKey.lastName, response.data.payload.lastName || '')
        appSessionStorage.setItem(sessionStorageKey.applicantId, response.data.payload.applicantId.toString())
        appSessionStorage.setItem(sessionStorageKey.loanApplicationId, response.data.payload.loanApplicationId.toString())
        appSessionStorage.setItem(sessionStorageKey.experimentName, response.data.payload.experimentName)
        appSessionStorage.setItem(sessionStorageKey.experimentOverrides, JSON.stringify(response.data.payload.experimentOverrides || {}))
        appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)

        if (response.data.payload.inviteType) {
            const inviteType = response.data.payload.inviteType
            appSessionStorage.setItem(sessionStorageKey.inviteType, inviteType)
            if (inviteType === 'applicant') {
                appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)

                if (response.data.payload.pifBonus) {
                    appSessionStorage.setItem(sessionStorageKey.pifBonus, String(response.data.payload.pifBonus))
                }

                appSessionStorage.setItemIfPresent(sessionStorageKey.pifSenderName, response.data.payload.pifSenderName)
            } else if (inviteType === 'mail') {
                appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.mailerOrigination)

                if (response.data.payload.isMailInviteExpired) {
                    appSessionStorage.setItem(sessionStorageKey.isMailInviteExpired, String(response.data.payload.isMailInviteExpired))
                }
            } else if (inviteType === 'realEstateAgent') {
                appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.realEstateAgent)
            } else {
                logger.error(`Unknown invite type ${inviteType}, cannot determine origination Flow`)
            }
        }
    }
}

const saveCoApplicantAuthResponse = (response: { data: any }) => {
    if (response.data.success) {
        appSessionStorage.setItem(sessionStorageKey.coApplicantJwtTokens, JSON.stringify(response.data.payload.coApplicantJwtTokens))
    }
}

export { getLead, createLoanAppFromExistingLoanApp, setApplicationAndApplicant, createCoApplicantLead, verifyEmployerEmail, isEmployerEmailVerified, getRealEstateAgentLead }
