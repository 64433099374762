<template>
    <auth-layout :loading="loading">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mt-3">
                    <div class="text-center">
                        <img
                            src="../../assets/images/components/navbar/aven.svg"
                            class="logo mb-5"
                            width="71"
                            alt=""
                        >
                        <h5
                            class="fw-normal mb-1"
                            v-html="userMessage"
                        />
                    </div>
                </div>
            </div>
        </div>
    </auth-layout>
</template>

<script>
    import Auth from '@/layouts/Auth'
    import { i18n } from '@/utils/i18n'
    import { verifyEmployerEmail } from '@/services/auth'
    import { logger } from '@/utils/logger'

    export default {
        components: {
            'auth-layout': Auth,
        },
        data() {
            return {
                loading: true,
                userMessage: i18n.t('pages.auth.employerEmailVerification.title'),
            }
        },
        mounted: async function () {
            try {
                const resp = await verifyEmployerEmail(this.$route.query.auth)

                if (!resp?.data?.success) {
                    throw new Error('Invalid auth token')
                }

                this.loading = false
                this.$logEvent('view_employer_email_verification')
                logger.info(JSON.stringify(resp))

                this.userMessage = i18n.t('pages.auth.employerEmailVerification.completed')
            } catch (error) {
                logger.info(`catch: ${error}`)
                this.loading = false
                this.userMessage = i18n.t('pages.auth.employerEmailVerification.failed')
            }
        },
        computed: {},
        methods: {},
    }
</script>
